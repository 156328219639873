import { ref } from "vue";
import { message } from "ant-design-vue";
import { FileInfo } from "@/types";
import { useStore } from "vuex";
const useUploadDraw = (init: Function) => {
  const store = useStore();
  const visible = ref<boolean>(false);
  const fileList = ref<any>([]);
  const handleUpload = (info: FileInfo) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      // 上传文件成功
      message.success(`${info.file.name} file uploaded successfully`);
      visible.value = true;
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const headers = {
    Authorization: store.state.Authorization,
  };
  const handleCloseUpload = () => {
    visible.value = false;
    fileList.value = [];
    init();
  };
  return {
    visible,
    fileList,
    handleUpload,
    headers,
    handleCloseUpload,
  };
};
export default useUploadDraw;
