import { useStore } from 'vuex';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { findbudgetExist } from '@/API/budget';
import { budgetGenerate } from '@/API/program';
import { message } from 'ant-design-vue';

const useBudgetCheck = (programAllStatus: any) => {
  const store = useStore();
  const router = useRouter();

  const generateBudget = () => {
    budgetGenerate(store.state.pgmData.programId).then((res: any) => {
      if (res.code === '0') {
        router.push({
          path: '/budgetinput',
          query: {
            pgmId: store.state.pgmData.programId,
            jump: 'true'
          }
        });
      }
    });
  };

  const checkBudgetExist = () => {
    findbudgetExist(store.state.pgmData.programId).then((res) => {
      if (!res) {
        if (programAllStatus.value.design !== 'Saved') {
          message.error('Program must be Saved status!');
          return;
        }
        generateBudget();
      } else {
        router.push({
          path: '/budgetinput',
          query: {
            pgmId: store.state.pgmData.programId,
            jump: 'true'
          }
        });
      }
    });
  };

  onBeforeRouteLeave((to, from) => {
    if (to.path === '/budgetinput' && to.query?.jump !== 'true') {
      checkBudgetExist();
      return false;
    }
  });
};

export default useBudgetCheck;
